/* eslint-disable @next/next/no-img-element */
import { ImageProps } from '@/ts/interfaces';

const Image: React.FC<ImageProps> = ({ src, alt, sizes, width, height, loading, srcSet, fetchPriority }) => (
  <img
    src={src}
    alt={alt}
    width={width}
    height={height}
    loading={loading}
    srcSet={srcSet}
    sizes={sizes}
    fetchPriority={fetchPriority}
  />
);

export default Image;
